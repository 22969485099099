@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

/* * */
body {
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
}

.inner-row {
  padding: 0px;
}

.form-bt {
  display: flex;
}

.main-container {
  max-width: 1300px;
  width: 100%;
  margin: 0px auto;
}

a {
  transition: all 0.3s ease-in;
}


/* * */
/* header */
.header {
  position: sticky;
  transition: all 0.3s ease-in;
  padding: 10px 0px;
  border-bottom: 1px solid #EBEBEB;
}

.header.sticky {
  z-index: 99999;
  top: 0px;
  box-shadow: 0 0.125rem 0.25rem #000;
  background-color: #EBEBEB;
}

.header .logo img {
  /* max-width: 200px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  /* margin-left: 65px; */

}

.header .inner-flx-bx {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .inner-flx-bx .log_out {
  background-color: #4472c4;
  padding: 6px 10px;
  border: 1px solid #2f528f;
  border-radius: 10px;
  cursor: pointer;
}

.header .inner-flx-bx .log_out:hover {
  background-color: #355998;
  border: 1px solid #4472c4;
  transition: all 0.3s ease-in;
}

.header .inner-flx-bx .log_out img {
  max-width: 30px;
  width: 100%;
}

/* header */
/* table block  */
.table-block .filter-block {
  padding: 30px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-block .filter-block a {
  background-color: #4472c4;
  color: #fff;
  text-decoration: none;
  padding: 0px;
  font-size: 15px;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid #2f528f;
  border-radius: 10px;
  display: block;
}

.table-block .filter-block a:hover {
  background-color: #355998;
  border: 1px solid #4472c4;
}

.table-block .filter-block .filter_img {
  background-color: #4472c4;
  padding: 6px 10px;
  border: 1px solid #2f528f;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  transition: all 0.3s ease-in;
}

.table-block .filter-block .filter_img:hover {
  background-color: #355998;
  border: 1px solid #4472c4;
}

.table-block .filter-block .filter_img svg {
  max-width: 30px;
  height: 30px;
  width: 100%;
}

.inner-table {
  background-color: #EBEBEB;
  padding: unset;
}

.inner-table thead th {
  background-color: #4472c4;
  color: #fff;
}

.inner-table .table {
  margin: 0px;
  word-wrap: break-word;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  transition: all 0.3s ease-in;
}

.inner-table .table img {
  max-width: 100%;
  height: 110px;
  object-fit: cover;
  width: 100%;
}

.inner-table .table td {
  /* display: flex; */
  /* align-items: center; */
  /* height: 0px; */
  vertical-align: middle;
  background-color: transparent;
}

.inner-table .table tr:hover {
  background-color: #f1f1f1;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.inner-table .table td a {
  word-wrap: break-word;
  color: #222 !important;
  text-decoration: none;
}

.inner-table .table td p {
  font-size: 15px;
  padding: 0px;
  margin: 0px;
}

.flex-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 0px;
}

.flex-pagination ul {
  display: flex;
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  gap: 10px;

}

.flex-pagination ul a {
  text-decoration: none;
  color: #222 !important;
  font-size: 15px;
}

.flex-pagination ul a:hover {
  color: #2f528f;
}

.flex-pagination .left-arrow svg {
  fill: #fff;
  max-width: 30px;
  height: 30px;
  background-color: #4472c4;
  border: 1px solid #2f528f;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.flex-pagination .right-arrow svg {
  fill: #fff;
  max-width: 30px;
  height: 30px;
  background-color: #4472c4;
  border: 1px solid #2f528f;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.flex-pagination .right-arrow svg:hover {
  background-color: #355998;
  border: 1px solid #4472c4;
}

.flex-pagination .left-arrow svg:hover {
  background-color: #355998;
  border: 1px solid #4472c4;
}

/* table block  */
.underline-text {
  position: relative;
}

.underline-text::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: #000;
  bottom: 22px;
  left: 26px;
}

/* ----------New CSS starts------------ */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* @media (max-width: 991px) { */
.container {
  width: 100%;
  padding: 0 12px;
}

.logo {
  display: flex;
  justify-content: center;
}

.logo a img {
  width: 140px;
  height: auto;
}

.header-text {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 25px;
}

.header-text h3 {
  margin: 0;
}

.header-text .log-out {
  background-color: #4472c4;
  padding: 6px 10px;
  border: 1px solid #2f528f;
  border-radius: 10px;
  cursor: pointer;
}

.header-text .log-out img {
  max-width: 30px;
  width: 100%;
}

/* home */

section.btn-group {
  width: 100%;
  padding: 20px 0 50px;
  height: calc(100vh - 280px);
}

.grp-1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.active {
  background-color: #4472c4;
  padding: 10px 20px 10px 50px;
  position: relative;
}

.active::before {
  content: "";
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
  left: 0;
  border-left: 16px solid #f8ecec;
  border-top: 24px solid transparent;
  border-bottom: 26px solid transparent;
}

.grp-2 a {
  text-decoration: none;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
  color: #b92020 !important;
}

.grp-1 a {
  text-decoration: none;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  color: #fff;
}

.disabled {
  background-color: #3c3c3c;
}

.disabled::before {
  all: unset;
}

a .disabled {
  color: #a7a7a7;
}

.grp-2 a {
  color: rgb(255, 181, 43);
}

/* home */

/* real time page */

section.table {
  padding: 10px 0;
}

table {
  max-width: 440px;
  width: 100%;
  border: 1px solid #000;
  /* border-collapse: collapse; */
}

.real-time-table tr td {
  padding: 20px 20px 20px 10px;
  /* background-color: #fff; */
  color: #000;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.real-time-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.real-time-table th {
  padding: 7px 10px 7px 10px !important;
  background-color: #4472c4;
  color: #fff;
  text-align: left;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}

.text-center p {
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 20px;
}

span.table-top {
  padding: 5px 10px 5px;
  display: block;
  border: 1px solid #000;
  width: fit-content;
  border-bottom: 0;
  position: relative;
  bottom: -1px;
  z-index: 9;
  background-color: #2f528f;
  color: #fff;
  font-size: 15px;
  line-height: 25px;
  /* font-weight: 500; */
  border-radius: 9px 9px 0px 0px;
}

.bottom-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.bottom-btn a:first-child {
  text-decoration: none;
}

.start-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #008000;
  border-radius: 12px;
  padding-top: 8px;
  padding-bottom: 25px;
}

.start-btn .btn-icon img {
  width: 45px;
  height: 50px;
}

.start-btn .btn-text p {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  color: #008000;
  border-bottom: 4px solid;
}

.stop-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ff0000;
  border-radius: 12px;
  padding-top: 8px;
  padding-bottom: 25px;
}

.stop-btn .btn-icon img {
  width: 80px;
  height: 70px;
}

.stop-btn .btn-text p {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  color: #ff0000;
  border-bottom: 4px solid;
}

.cancel-btn {
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

/* real time page */

/* real-time-allocation-table */

.real-time-allocation-table tr td {
  padding: 6px;
  /* background-color: #FFF; */
  color: #000;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.real-time-allocation-table tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.real-time-allocation-table th {
  padding: 6px;
  background-color: #4472c4;
  color: #fff;
  text-align: left;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.back-btn a {
  color: #fff;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  background-color: #2f528f;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
}

.back-btn {
  margin-bottom: 16px;
}

/* real-time-allocation-table */

/* summary style */

.summary {
  padding: 20px 0;
}

.summary .inner-table {
  background-color: unset;
}

.on-off {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}

.on-off .icon img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: green;
}

.on-off .txt p {
  font-size: 15px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
}

.summary-table {
  border-collapse: collapse;
  border: unset;
  margin: 0 auto;
}

.summary-table tr td {
  padding: 6px;
  color: #000;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.summary-table tr td:first-child {
  display: flex;
}

/* modal */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgba(0, 0, 0, 0.7);
  padding: 100px 20px;
  border-radius: 50px;
  max-width: 300px;
  width: 100%;
  text-align: center;
  min-height: 65%;
}

.modal-content p {
  color: #fff;
  font-size: 15px;
  line-height: 28px;
  font-weight: 500;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
}

.modal-buttons a {
  text-decoration: none;
  font-weight: 600;
}

.confirm-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  border: 1px solid #008000;
  border-radius: 12px;
  padding-top: 50px;
  padding-bottom: 25px;
  gap: 12px;
  background-color: #fff;
}

.confirm-btn .btn-icon img {
  width: 60px;
  height: 60px;
}

.confirm-btn .btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  border-bottom: 4px solid #008000;
  width: 100%;
}

.confirm-btn .btn-text p {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  color: #008000;
  margin-bottom: 0px;
}

.modal-buttons .cancel-btn {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  border: 1px solid #ff0000;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 15px;
  gap: 12px;
  background-color: #fff;
}

.modal-buttons .cancel-btn .btn-icon img {
  width: 60px;
  height: 60px;
}

.modal-buttons .cancel-btn .btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  border-bottom: 4px solid #ff0000;
  width: 100%;
}

.modal-buttons .cancel-btn .btn-text p {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  color: #ff0000;
  margin-bottom: 0px;
}

/* modal */

/* summary style */

.erase-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 20px;
}

.erase-btn a img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.header-text h2 {
  margin-top: 12px;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
}

.home-page .header-text {
  justify-content: flex-end;
  gap: 40px;
}

table.mat-elevation-z8 tr th:first-child {
  padding-right: 10px;
  max-width: 50px;
}

.table-striped tr td {
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.table-striped thead th {
  font-size: 15px;
  line-height: 25px;
}

.table-striped tr td button {
  padding: 2px 6px;
  border: 0px solid;
  background-color: #ccc;
}

/* } */

table.mat-elevation-z8 tr th {
  padding: 10px 50px 20px 10px;
  background-color: #4472c4 !important;
  color: #fff;
  text-align: left;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  border: 1px;
}

.toast-container {
  position: fixed !important;
}

.mat-sort-header-arrow {
  color: #fff;
}

a.register,
a.login-a {
  display: flex;
  justify-content: center;
}

/* ---------Ends--------------- */


/* mobile */

@media (max-width: 991px) {
  .btn-group .grp-2 {
    position: absolute;
    bottom: 0;
    /* max-width: 388px; */
    width: 100%;
    margin-bottom: 50px;
  }

  section.btn-group {
    height: calc(100vh - 180px);
  }

  section.table.real-table-section .inner-table {
    height: 400px;
  }

  .table .inner-table {
    padding: unset;
    max-height: 450px;
  }

  table.mat-elevation-z8 tr th {
    font-size: 15px;
    line-height: 25px;
  }
}

/* mobile */

/* desktop */

@media (min-width: 992px) {
  .btn-group .grp-2 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  table {
    max-width: 1440px;
  }

  .table .inner-table {
    padding: unset;
    max-height: 450px;
  }

  .header-text {
    justify-content: space-between;
  }

  .home-page .header-text {
    gap: 505px;
  }

  .start-btn,
  .stop-btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .summary-table {
    max-width: 450px;
  }

  .table-block .main-container {
    max-width: 1300px;
  }

  table thead {
    position: sticky;
    top: 0;
  }

  section.table.real-table-section .inner-table table {
    border: unset;
  }

  .stop-btn {
    margin-bottom: 25px;
  }

  .modal-content {
    max-width: 600px;
    justify-content: center;
  }

  .modal-content p {
    font-size: 15px;
  }
}

section.btn-group.btn-2 .btn-wrp {
  position: relative;
}

section.btn-group.btn-2 {
  height: unset;
}

table thead {
  position: sticky;
  top: 0;
}

.confirm-btn {
  padding-top: 18px;
  padding-bottom: 15px;
}

.pagination {
  font-size: 15px;
  line-height: 25px;
}

table.mat-elevation-z8 tbody tr td {
  font-size: 15px;
  line-height: 25px;
}

table.mat-elevation-z8 thead tr th {
  font-size: 15px;
  line-height: 28px;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm,
div:where(.swal2-container) button:where(.swal2-styled).swal2-deny {
  font-size: 15px !important;
  line-height: 25px;
}

.login_screen .mat-mdc-form-field-infix label {
  font-size: 15px;
  line-height: 25px;
}

.login_screen .lg-btn,
.login_screen .register,
.login_screen .login-a {
  font-size: 15px;
  line-height: 25px;
}

/* desktop */


.swal2-modal .swal2-title {
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

p.ng-star-inserted {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
}

.header-text.inner-module-custom-class{
  gap: 0;
}

.header-text.inner-module-custom-class h2 {
  flex: 1;
}

@media only screen and (max-width: 768px){
  .modal-content{
      max-width: calc(100% - 30px);
      padding: 30px 20px;
      justify-content: center;
  }
}

.example-button-row{
  margin-top: 20px;
}

#myModal .modal-content {
  padding: 20px;
  margin-top: 10%;
  margin-bottom: 20px;
}

.modal-content .modal-buttons a{
  padding: 0;
  border-radius: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px){
  #myModal .modal-content{
      max-width: calc(100% - 140px);
  }
}